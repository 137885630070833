<template>
   <div class="ph-inpage-widget ph-inpage-widget-bottom-right ph-inpage-widget--ios">
      <div class="ph-inpage-widget__content">
        <div class="ph-inpage-widget__row">
          <div class="ph-inpage-widget__thumb ph-inpage-widget__thumb-rounded-4">
            <img :src="require('@/assets/panel/images/inpage/empty.svg')" v-show="!imgIcon" alt="" />
            <img :src="imgIcon" v-show="imgIcon" />
          </div>
          <div class="ph-inpage-widget__txt">
            <div class="ph-inpage-widget__title ph-inpage-widget__txt-overflow-1">
              {{ adTitle }}
            </div>
            <div class="ph-inpage-widget__header ph-inpage-widget__txt-overflow-2">
              {{ adText }}
            </div>
          </div>
        </div>
        <div class="ph-inpage-widget__close">&#10005;</div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['adTitle','adText','imgIcon']
}
</script>